import "./styles.scss"

import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { useInView } from "framer-motion"

import { Wide, Tall, Mobile } from "./components"

const Hero = () => {
  const ref = useRef(null)

  const [viewport, setViewport] = useState(null)

  useLayoutEffect(() => {
    const handleViewport = () => {
      if (window) {
        const window_width = window.innerWidth
        const window_height = window.innerHeight

        const isMediumDesktop = window_width <= 1600
        const isSmallDesktop = window_width <= 1200

        const ratio = (window_height / window_width) * 100

        setViewport(
          ratio >= (isSmallDesktop ? 70 : isMediumDesktop ? 65 : 62.5)
            ? "tall"
            : "wide"
        )
      }
    }

    handleViewport()

    window.addEventListener("resize", handleViewport)

    return () => window.removeEventListener("resize", handleViewport)
  }, [])

  const isInView = useInView(ref, {
    amount: 0.4,
  })

  useEffect(() => {
    const cta = document.querySelector(".hugo-cta")
    const className = "hugo-cta--hidden"

    if (cta) {
      if (isInView) {
        cta.classList.add(className)
      } else {
        cta.classList.remove(className)
      }
    }
  }, [isInView])

  return (
    <section className="hugo-hero" ref={ref}>
      {viewport !== null ? viewport === "wide" ? <Wide /> : <Tall /> : null}
      <Mobile />
    </section>
  )
}

export default Hero
