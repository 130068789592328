import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import AnimatedMask from "components/AnimatedMask"

import { HUGO_HERO_DATA } from "../../data"

const Mobile = () => {
  return (
    <div className="hugo-hero-mobile">
      <div className="hugo-hero-mobile__investment">
        <div className="container-fluid">
          <div className="hugo-hero-mobile__investment-logo">
            <img
              src={HUGO_HERO_DATA.investment.logo.image}
              alt={HUGO_HERO_DATA.investment.logo.alt}
            />
          </div>
          <h4>{HUGO_HERO_DATA.investment.stage}</h4>
        </div>
      </div>
      <div className="container-fluid">
        <motion.h2
          className="hugo-hero-mobile__heading"
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
          }}
          viewport={{
            once: true,
          }}
          transition={{ type: "tween", duration: 1 }}
        >
          {HUGO_HERO_DATA.heading.left} {HUGO_HERO_DATA.heading.right}
        </motion.h2>
      </div>
      <div className="hugo-hero-mobile__image">
        <img src={HUGO_HERO_DATA.background.mobile} alt="Browar Hugo Scobel" />
        <AnimatedMask variant="hugo-hero" />
      </div>
      <div className="container-fluid">
        <div className="hugo-hero-mobile__caption">
          <p>{HUGO_HERO_DATA.caption.name}</p>
          <p>{HUGO_HERO_DATA.caption.location}</p>
        </div>
      </div>
    </div>
  )
}

export default Mobile
