export const HUGO_HERO_DATA = {
  investment: {
    logo: {
      image: require("assets/images/hugo/logo.svg").default,
      alt: "BROWAR HUGO SCOBEL",
    },
    stage: "Etap I",
  },
  heading: {
    left: "LEGENDA",
    right: "POWRACA",
  },
  background: {
    desktop: require("assets/images/hugo/hero-image.jpg").default,
    mobile: require("assets/images/hugo/hero-image--mobile.jpg").default,
  },
  caption: {
    location: "Gliwice, plac Piastów",
    name: "Dom pod Lwem",
  },
}
