import "./styles.scss"

import React from "react"

const History = () => {
  return (
    <section className="hugo-history">
      <div className="container-hugo">
        <div className="row flex-md-row-reverse">
          <div className="col-md-6 offset-md-1">
            <div className="hugo-heading hugo-history__heading">
              <div className="hugo-label">Rewitalizacja</div>
              <h2>Historyczne miejsce, które czekało na nową opowieść</h2>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-2">
                <div className="hugo-history__image hugo-history__image--2">
                  <img
                    src={
                      require("assets/images/hugo/history-image-2.jpg").default
                    }
                    alt=""
                  />
                  <h4>Dawna brama browaru</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="hugo-history__image hugo-history__image--1">
              <img
                src={require("assets/images/hugo/history-image-1.jpg").default}
                alt=""
              />
              <h4>Reklama browaru „Löwenbier-Brauerei” z około 1896 roku</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <div className="hugo-history__image hugo-history__image--3">
              <img
                src={require("assets/images/hugo/history-image-3.jpg").default}
                alt=""
              />
              <h4>Wnętrze dawnego browaru</h4>
            </div>
          </div>
        </div>
        <div className="row align-items-center flex-md-row-reverse">
          <div className="col-md-7 offset-md-1">
            <div className="hugo-history__image hugo-history__image--4">
              <img
                src={require("assets/images/hugo/history-image-4.jpg").default}
                alt=""
              />
              <h4>Warzelnia browarniana</h4>
            </div>
          </div>
          <div className="col-md-4">
            <h4>Zanurz się w historii miejsca i poznaj wizję jego jutra</h4>
            {/* <button className="hugo-history__cta">
              Wchodzę<span>→</span>
            </button> */}
          </div>
        </div>
      </div>
      <div className="hugo-history__background" />
    </section>
  )
}

export default History
