import "./styles.scss"

import React, { useState } from "react"
import { motion } from "framer-motion"

import { Form } from "../components"
import AnimatedMask from "components/AnimatedMask"

const variants = {
  hidden: {
    y: 32,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const getTransition = delay => ({
  type: "tween",
  duration: 0.6,
  delay,
})

const Contact = () => {
  const [animate, setAnimate] = useState(false)

  return (
    <>
      <span id="contact" className="anchor anchor--small" />
      <section className="hugo-contact">
        <div className="container-hugo">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div
                className="hugo-contact__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/hugo/contact-image.jpg").default
                  }')`,
                }}
              >
                <AnimatedMask
                  variant="hugo-contact"
                  callback={() => setAnimate(true)}
                />
              </div>
            </div>

            <div className="col-md-6 offset-lg-1">
              <div className="hugo-contact__inner">
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.4)}
                >
                  <div className="hugo-label">Kontakt</div>
                  <h4>
                    Zapytaj o mieszkania
                    <br />w Domu pod Lwem
                  </h4>
                </motion.div>

                <motion.div
                  className="hugo-contact__form"
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.6)}
                >
                  <Form />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
