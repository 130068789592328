import "./styles.scss"

import React, { useRef } from "react"
import { useScroll } from "framer-motion"

import StringIllumination from "components/StringIllumination"

const About = () => {
  const quoteRef = useRef(null)

  const { scrollYProgress: quoteOneProgress } = useScroll({
    target: quoteRef,
    offset: ["start end", "center end"],
  })

  const { scrollYProgress: quoteTwoProgress } = useScroll({
    target: quoteRef,
    offset: ["center end", "end"],
  })

  return (
    <>
      <span id="about" className="anchor anchor--small" />
      <section className="hugo-about">
        <div className="hugo-about__quote" ref={quoteRef}>
          <div className="hugo-about__quote-inner">
            <div className="container-hugo">
              <div className="hugo-about__quote-wrapper">
                <div className="hugo-about__quote-content">
                  <p>
                    <StringIllumination
                      string="„Uzupełniamy historyczną substancję kwartału nowymi pierwiastkami, które nie rywalizują z przeszłością i nie naśladują jej, lecz ją dopełniają w języku nowoczesnej architektury”"
                      progress={quoteOneProgress}
                      colors={{
                        start: "#000000",
                        end: "#EFDBB7",
                      }}
                    />
                  </p>
                  <p>
                    <StringIllumination
                      string="„Projektowanie jest sztuką kreowania otaczającego nas świata – przenosi dawne wartości w nową epokę, łączy je z wyzwaniami współczesności i zmieniającymi się potrzebami człowieka”"
                      progress={quoteTwoProgress}
                      colors={{
                        start: "#000000",
                        end: "#EFDBB7",
                      }}
                    />
                  </p>
                </div>
                <p className="hugo-about__quote-author">
                  Zespół Trust Investment
                </p>
              </div>
            </div>
            <div className="hugo-about__shape hugo-about__shape--left" />
            <div className="hugo-about__shape hugo-about__shape--right" />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
