import "./styles.scss"

import React from "react"

const Residence = () => {
  return (
    <section className="hugo-residence">
      <div className="container-hugo">
        <div className="row">
          <div className="col-xl-8 col-md-12">
            <div className="row">
              <div className="col-xl-5 col-md-6">
                <div className="hugo-heading hugo-residence__heading">
                  <div className="hugo-label">Styl, komfort i innowacje</div>
                  <h2>Nowoczesna rezydencja</h2>
                  <p>
                    Wyrafinowana architektura w minimalistycznym stylu oraz
                    udogodnienia na miarę komfortowej miejskiej rezydencji
                    tworzą nietuzinkowe ramy dla wysokiej jakości życia.
                  </p>
                </div>
              </div>
              <div className="col-md-4 offset-md-1">
                <div className="hugo-residence__image hugo-residence__image--1">
                  <img
                    src={
                      require("assets/images/hugo/residence-image-1.jpg")
                        .default
                    }
                    alt=""
                  />
                  <h4>Wnętrze apartamentu z atresolą</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-md-6 offset-xl-3">
                <ul className="hugo-residence__list hugo-residence__list--1">
                  <li>Penthouse’y z antresolami i tarasami na dachu</li>
                  <li>Przestronne balkony i tarasy nawet do 55 m²</li>
                  <li>Reprezentacyjny hol z muzyką i zapachem</li>
                  <li>Panoramiczne, wysokie okna</li>
                  <li>Eleganckie materiały wykończeniowe</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 offset-xl-0 offset-md-6">
            <div className="hugo-residence__image hugo-residence__image--2">
              <img
                src={
                  require("assets/images/hugo/residence-image-2.jpg").default
                }
                alt=""
              />
              <h4>Strefa dzienna</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="hugo-residence__image hugo-residence__image--3">
              <img
                src={
                  require("assets/images/hugo/residence-image-3.jpg").default
                }
                alt=""
              />
              <h4>Sypialnia</h4>
            </div>
            <div className="hugo-residence__content">
              <p>
                Zaprojektowana, by wybiegać w przyszłość oraz sprostać
                wymaganiom pod względem technologii przyjaznych dla codziennego
                życia, troski o naturalne środowisko i wygodę mieszkańców.
              </p>
            </div>
          </div>
          <div className="col-md-6 offset-md-1">
            <div className="hugo-residence__image hugo-residence__image--4">
              <img
                src={
                  require("assets/images/hugo/residence-image-4.jpg").default
                }
                alt=""
              />
              <h4>Lobby wejściowe</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="hugo-residence__image hugo-residence__image--5">
              <img
                src={
                  require("assets/images/hugo/residence-image-5.jpg").default
                }
                alt=""
              />
              <div className="hugo-residence__image hugo-residence__image--6">
                <div>
                  <img
                    src={
                      require("assets/images/hugo/residence-image-6.jpg")
                        .default
                    }
                    alt=""
                  />
                </div>
              </div>
              <h4>Części wspólne</h4>
            </div>
          </div>
          <div className="col-md-6 offset-md-1">
            <ul className="hugo-residence__list hugo-residence__list--2">
              <li>
                Blue Bolt – aplikacja zamiast klucza do drzwi
                <br />i pilota do garażu
              </li>
              <li>Prywatny punkt wysyłki paczek</li>
              <li>Instalacja PV na dachu zasilająca części wspólne</li>
              <li>Starannie zaprojektowane tereny zielone</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Residence
