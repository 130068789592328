import "./styles.scss"

import React, { useState, useRef } from "react"
import Slider from "react-slick"
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
  useMotionTemplate,
} from "framer-motion"

import { useCursor } from "context/cursor"

const slides_1 = [
  {
    image: require("assets/images/hugo/entertainment-s1_i1.jpg").default,
    label: "Rewitalizacja zabytkowej zabudowy browaru",
  },
  {
    image: require("assets/images/hugo/entertainment-s1_i2.jpg").default,
    label: "Wielofunkcyjny kwartał – mieszkania, biura, restauracje, kawiarnie",
  },
]

const slides_2 = [
  {
    image: require("assets/images/hugo/entertainment-s2_i1.jpg").default,
    label: "Unikatowa lokalizacja obok Dworca PKP",
  },
  {
    image: require("assets/images/hugo/entertainment-s2_i2.jpg").default,
    label: "Wizjonerska architektura",
  },
]

const slides_3 = [
  {
    image: require("assets/images/hugo/entertainment-s3_i1.jpg").default,
    label: "Zazielenimy 50% całego terenu",
  },
  {
    image: require("assets/images/hugo/entertainment-s3_i2.jpg").default,
    label: "Zamknięty dla ruchu samochodów",
  },
]

const captionAnimation = {
  initial: {
    y: 10,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -10,
    opacity: 0,
  },
  transition: {
    type: "tween",
    ease: "easeInOut",
    duration: 0.35,
  },
}

const Entertainment = () => {
  const { setCursor } = useCursor()

  const ref = useRef(null)

  const [slideIndex, setSlideIndex] = useState(0)

  const s1_ref = useRef(null)
  const s2_ref = useRef(null)
  const s3_ref = useRef(null)

  const getSettings = isPrimary => ({
    dots: false,
    arrows: false,
    infinite: true,
    draggable: false,
    touchMove: false,
    speed: 1000,
    autoplay: isPrimary ? true : false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    puaseOnFocus: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      if (isPrimary) {
        const slidesLength = slides_1.length - 1

        setSlideIndex(newIndex)

        if (oldIndex === 0 && newIndex === slidesLength) {
          s2_ref.current.slickPrev()
          s3_ref.current.slickPrev()
        } else {
          if (
            oldIndex < newIndex ||
            (oldIndex === slidesLength && newIndex === 0)
          ) {
            s2_ref.current.slickNext()
            s3_ref.current.slickNext()
          } else {
            s2_ref.current.slickPrev()
            s3_ref.current.slickPrev()
          }
        }
      }
    },
  })

  const getNavProps = (direction = "left", callbackEnter, callbackLeave) => ({
    onMouseEnter: () => {
      if (callbackEnter && typeof callbackEnter === "function") {
        callbackEnter()
      }

      setCursor([true, `arrow-${direction}`])
    },
    onMouseLeave: () => {
      if (callbackLeave && typeof callbackLeave === "function") {
        callbackLeave()
      }

      setCursor([null, `arrow-${direction}`])
    },
    onClick: () =>
      direction === "left"
        ? s1_ref.current.slickPrev()
        : s1_ref.current.slickNext(),
  })

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  })

  const timing = [0, 1]
  const shape_y = useTransform(progress, timing, [-12.5, 25])
  const shape_transform = useMotionTemplate`translate(0, ${shape_y}%)`

  return (
    <section className="hugo-entertainment" ref={ref}>
      <div className="container-hugo">
        <div className="row">
          <div className="col-lg-8">
            <div className="hugo-entertainment__image hugo-entertainment__image--1">
              <Slider {...getSettings(true)} ref={s1_ref}>
                {slides_1.map((slide, index) => (
                  <div key={index} className="hugo-entertainment__slide">
                    <img src={slide.image} alt={slide.label} />
                    <div className="hugo-entertainment__mask">
                      <button
                        className="hugo-entertainment__mask-action hugo-entertainment__mask-action--prev"
                        type="button"
                        {...getNavProps(
                          "left",
                          () => s1_ref.current.slickPause(),
                          () => s1_ref.current.slickPlay()
                        )}
                      >
                        ←
                      </button>
                      <button
                        className="hugo-entertainment__mask-action hugo-entertainment__mask-action--next"
                        type="button"
                        {...getNavProps(
                          "right",
                          () => s1_ref.current.slickPause(),
                          () => s1_ref.current.slickPlay()
                        )}
                      >
                        →
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>

              <div className="hugo-heading hugo-entertainment__heading">
                <div className="hugo-label">WIZJA MIEJSCA</div>
                <h2>
                  BROWAR
                  <br />
                  HUGO SCOBEL
                </h2>
              </div>
              <div className="hugo-entertainment__image-caption">
                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.h5
                    key={slides_1[slideIndex].label}
                    {...captionAnimation}
                  >
                    {slides_1[slideIndex].label}
                  </motion.h5>
                </AnimatePresence>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="hugo-entertainment__image hugo-entertainment__image--2">
              <img
                src={
                  require("assets/images/hugo/entertainment-static-1.jpg")
                    .default
                }
                alt=""
              />
            </div>
            <div className="hugo-entertainment__image hugo-entertainment__image--3 hugo-entertainment__image--desktop">
              <Slider {...getSettings()} ref={s3_ref}>
                {slides_3.map((slide, index) => (
                  <div key={index}>
                    <img src={slide.image} alt={slide.label} />
                  </div>
                ))}
              </Slider>
              <div className="hugo-entertainment__image-caption">
                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.h5
                    key={slides_3[slideIndex].label}
                    {...captionAnimation}
                  >
                    {slides_3[slideIndex].label}
                  </motion.h5>
                </AnimatePresence>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="hugo-entertainment__image hugo-entertainment__image--4 hugo-entertainment__image--desktop">
              <Slider {...getSettings()} ref={s2_ref}>
                {slides_2.map((slide, index) => (
                  <div key={index}>
                    <img src={slide.image} alt={slide.label} />
                  </div>
                ))}
              </Slider>
              <div className="hugo-entertainment__image-caption">
                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.h5
                    key={slides_2[slideIndex].label}
                    {...captionAnimation}
                  >
                    {slides_2[slideIndex].label}
                  </motion.h5>
                </AnimatePresence>
              </div>
            </div>
          </div>

          <div className="col-lg-6 offset-lg-1">
            <div className="hugo-entertainment__image hugo-entertainment__image--5">
              <img
                src={
                  require("assets/images/hugo/entertainment-static-2.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="hugo-entertainment__navigation">
        <button
          className="hugo-entertainment__navigation-arrow hugo-entertainment__navigation-arrow--prev"
          type="button"
          {...getNavProps()}
        >
          ←
        </button>
        <button
          className="hugo-entertainment__navigation-arrow hugo-entertainment__navigation-arrow--next"
          type="button"
          {...getNavProps("right")}
        >
          →
        </button>
      </div>

      <motion.div
        className="hugo-entertainment__shape"
        style={{ transform: shape_transform }}
      />
    </section>
  )
}

export default Entertainment
