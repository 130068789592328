import "./styles.scss"

import React, { useRef } from "react"
import {
  motion,
  useMotionTemplate,
  useTransform,
  useScroll,
} from "framer-motion"
import scrollToElement from "scroll-to-element"

const Baner = () => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end"],
  })
  const timing = [0, 1]

  const cta_opacity = useTransform(progress, timing, [0, 1])
  const shapes_left_x = useTransform(progress, timing, [-12.5, 0])
  const shapes_left_transform = useMotionTemplate`translate(${shapes_left_x}%, 0)`
  const shapes_right_x = useTransform(progress, timing, [12.5, 0])
  const shapes_right_transform = useMotionTemplate`translate(${shapes_right_x}%, 0)`

  return (
    <section className="hugo-baner" ref={ref}>
      <motion.div className="container-hugo" style={{ opacity: cta_opacity }}>
        <button
          className="hugo-baner__cta"
          onClick={() => scrollToElement("#contact")}
        >
          Porozmawiajmy<span>→</span>
        </button>
      </motion.div>

      <motion.div
        className="hugo-baner__shape hugo-baner__shape--left"
        style={{ transform: shapes_left_transform }}
      />
      <motion.div
        className="hugo-baner__shape hugo-baner__shape--right"
        style={{ transform: shapes_right_transform }}
      />
    </section>
  )
}

export default Baner
