import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox, { More } from "components/Checkbox"

import { useLocale } from "context/locale"

const Form = () => {
  const { t } = useLocale()

  const formID = "19379"

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    legal: false,
  })
  const [isSending, setIsSending] = useState(false)

  const formSubmit = e => {
    e.preventDefault()

    setIsSending(true)

    let form = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      form.set(key, value)
    })

    axios({
      method: "post",
      url: `https://trust-investment.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        navigate(t("/kontakt-potwierdzenie/"))
      })
      .catch(_ => {
        setIsSending(false)
      })
  }

  return (
    <form onSubmit={formSubmit}>
      <Input
        placeholder={`Imię*`}
        type="text"
        id="first_name"
        name="first_name"
        value={formData.firstName}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            firstName: e.target.value,
          }))
        }
        required
      />
      <Input
        placeholder={`Nazwisko*`}
        type="text"
        id="last_name"
        name="last_name"
        value={formData.lastName}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            lastName: e.target.value,
          }))
        }
        required
      />
      <Input
        placeholder={`Adres e-mail*`}
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            email: e.target.value,
          }))
        }
        required
      />
      <Input
        placeholder={`Numer telefonu*`}
        type="phone"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            phone: e.target.value,
          }))
        }
        required
      />
      <div className="contact-form__action">
        <Checkbox
          name="legal"
          checked={formData.legal}
          onChange={e =>
            setFormData(prevData => ({
              ...prevData,
              legal: e.target.checked,
            }))
          }
          required
        >
          <More
            string={t(
              "Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez TRUST INVESTMENT SPÓŁKA AKCYJNA, ul. Robotnicza 1, 25-662 Kielce NIP: 6631877218, REGON: 381244959, KRS: 0000747790 (Administrator danych), w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej polityce prywatności."
            )}
          />
        </Checkbox>
      </div>
      <div className="hugo-contact__cta">
        <button type="submit" disabled={!formData.legal}>
          {isSending ? `${t("Wysyłanie")}...` : t("Wyślij wiadomość")}
          <span>→</span>
        </button>
      </div>
    </form>
  )
}

export default Form
