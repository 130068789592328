import "./styles.scss"

import React, { useRef } from "react"
import Slider from "react-slick"

import { useCursor } from "context/cursor"

const data = new Array(6)
  .fill()
  .map(
    (_, index) =>
      require(`assets/images/hugo/gallery/image-${index + 1}.jpg`).default
  )

const Gallery = () => {
  const { setCursor } = useCursor()

  const sliderRef = useRef(null)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    draggable: true,
    touchMove: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    puaseOnFocus: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const getNavProps = (direction = "left") => ({
    onMouseEnter: () => setCursor([true, `arrow-${direction}`]),
    onMouseLeave: () => setCursor([null, `arrow-${direction}`]),
    onClick: () =>
      direction === "left"
        ? sliderRef.current.slickPrev()
        : sliderRef.current.slickNext(),
  })

  return (
    <>
      <span id="gallery" className="anchor anchor--small" />
      <section className="hugo-gallery">
        <Slider {...settings} ref={sliderRef}>
          {data.map((image, index) => (
            <div key={index}>
              <div
                className="hugo-gallery__item"
                style={{ backgroundImage: `url(${image})` }}
              />
            </div>
          ))}
        </Slider>
        <div className="hugo-gallery__navigation">
          <button
            className="hugo-gallery__navigation-arrow hugo-gallery__navigation-arrow--prev"
            type="button"
            {...getNavProps()}
          >
            ←
          </button>
          <button
            className="hugo-gallery__navigation-arrow hugo-gallery__navigation-arrow--next"
            type="button"
            {...getNavProps("right")}
          >
            →
          </button>
        </div>
      </section>
    </>
  )
}

export default Gallery
