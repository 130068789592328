import "./styles.scss"

import React from "react"
import { Helmet } from "react-helmet"

const Apartments = () => {
  return (
    <section className="hugo-apartments">
      <Helmet>
        <script
          src="https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/js/main.js"
          onLoad={`(function(){SM.init('${"1372167b-c0e6-4f89-990a-611b6cf2abd2"}', 'smart-makieta', { '--color-main': '#ac875c' }, {showPrice: true, autoscrollDesktop: true})})()`}
        />
      </Helmet>

      <div className="hugo-heading hugo-apartments__heading">
        <div className="container-hugo">
          <div className="hugo-label">Mieszkania</div>
          <h2>
            Wybierz swój apartament
            <br />i zamieszkaj wśród dobrych historii
          </h2>
        </div>
      </div>

      <span id="apartments" className="anchor anchor--small" />

      <div id="smart-makieta"></div>

      <div className="hugo-apartments__content">
        <div className="container-hugo">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="hugo-heading hugo-apartments__content-heading">
                <h2>
                  Dom
                  <br />
                  pod Lwem
                </h2>
                <p>
                  Nowoczesna ikona Browaru Hugo Scobel. W swojej nazwie
                  przywołuje dawną ulicę Lwią – Löwenstrasse.
                </p>
                <div className="hugo-apartments__content-heading-shape" />
              </div>
            </div>
            <div className="col-xl-4 offset-xl-3 col-lg-5 offset-lg-2">
              <div className="hugo-apartments__content-description">
                <p>
                  Obejmuje 83 wyjątkowe mieszkania o podwyższonym standardzie, w
                  tym 6 penthouse’ów na ostatniej kondygnacji.
                </p>
                <p>
                  Tworzy unikalne miejsce do życia w najatrakcyjniejszym punkcie
                  miasta, z autentyczną historią tuż za progiem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hugo-apartments__shape" />
    </section>
  )
}

export default Apartments
