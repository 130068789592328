import "./styles.scss"

import React, { useRef } from "react"
import {
  useMotionTemplate,
  useScroll,
  motion,
  useTransform,
} from "framer-motion"

import { HUGO_HERO_DATA } from "../../data"

const Tall = () => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start", "end"],
  })

  const timing = [0, 1]
  const timing_prehalf = [0, 0.5]

  const investment_y = useTransform(progress, timing, [0, -100])
  const investment_y_transform = useMotionTemplate`translate(0, ${investment_y}%)`

  const heading_left_y = useTransform(progress, timing, [0, 100])
  const heading_left_y_transform = useMotionTemplate`translate(0, ${heading_left_y}%)`
  const heading_right_y = useTransform(progress, timing, [0, -100])
  const heading_right_y_transform = useMotionTemplate`translate(0, ${heading_right_y}%)`
  const heading_opacity = useTransform(progress, timing, [1, 0])

  const image_cp_top = useTransform(progress, timing, [12.91666667, 0])
  const image_cp_right = useTransform(progress, timing, [64.16666667, 100])
  const image_cp_bottom = useTransform(progress, timing, [87.08333333, 100])
  const image_cp_left = useTransform(progress, timing, [35.83333333, 0])
  const image_cp_transform = useMotionTemplate`polygon(${image_cp_right}% ${image_cp_top}%, ${image_cp_right}% ${image_cp_bottom}%, ${image_cp_left}% ${image_cp_bottom}%,  ${image_cp_left}% ${image_cp_top}%)`

  const image_scale = useTransform(progress, timing, [0.88, 1])
  const image_scale_transform = useMotionTemplate`scale(${image_scale})`

  const caption_opacity = useTransform(progress, timing_prehalf, [1, 0])

  return (
    <div className="hugo-hero-tall" ref={ref}>
      <motion.div
        className="hugo-hero-tall__wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.6 }}
      >
        <div className="hugo-hero-tall__wrapper-inner">
          <div className="hugo-hero-tall__container">
            <motion.div
              className="hugo-hero-tall__investment"
              style={{
                transform: investment_y_transform,
              }}
            >
              <div className="container-fluid">
                <div className="hugo-hero-tall__investment-logo">
                  <img
                    src={HUGO_HERO_DATA.investment.logo.image}
                    alt={HUGO_HERO_DATA.investment.logo.alt}
                  />
                </div>
                <h4>{HUGO_HERO_DATA.investment.stage}</h4>
              </div>
            </motion.div>
            <div className="hugo-hero-tall__heading">
              <div className="hugo-hero-tall__heading-row">
                <motion.div
                  className="hugo-hero-tall__heading-item hugo-hero-tall__heading-item--left"
                  style={{
                    opacity: heading_opacity,
                    transform: heading_left_y_transform,
                  }}
                >
                  <div>{HUGO_HERO_DATA.heading.left}</div>
                </motion.div>
                <motion.div
                  className="hugo-hero-tall__heading-item hugo-hero-tall__heading-item--right"
                  style={{
                    opacity: heading_opacity,
                    transform: heading_right_y_transform,
                  }}
                >
                  <div>{HUGO_HERO_DATA.heading.right}</div>
                </motion.div>
              </div>
            </div>
            <motion.div
              className="hugo-hero-tall__image"
              style={{ clipPath: image_cp_transform }}
            >
              <motion.div
                className="hugo-hero-tall__image-inner"
                style={{
                  backgroundImage: `url(${HUGO_HERO_DATA.background.desktop})`,
                  transform: image_scale_transform,
                }}
              />
            </motion.div>
            <motion.div
              className="hugo-hero-tall__caption"
              style={{ opacity: caption_opacity }}
            >
              <div className="hugo-hero-tall__caption-inner">
                <p>{HUGO_HERO_DATA.caption.location}</p>
                <p>{HUGO_HERO_DATA.caption.name}</p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Tall
