import "./styles.scss"

import React from "react"

const Location = () => {
  return (
    <>
      <span id="location" className="anchor anchor--small" />
      <section className="hugo-location">
        <div className="container-hugo">
          <div className="row">
            <div className="col-xl-4 col-sm-12">
              <div className="hugo-heading hugo-location__heading">
                <div className="container-hugo">
                  <div className="hugo-label">Lokalizacja</div>
                  <h2>Legendarne miejsce, wyjątkowy styl życia</h2>
                  <p>
                    W samym sercu Gliwic, gdzie miejski styl życia łączy się
                    doskonale z autentycznym klimatem dawnego browaru.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-7 offset-xl-0 offset-md-1">
              <div
                className="hugo-location__image"
                style={{
                  backgroundImage: `url(${
                    require("assets/images/hugo/location-image.jpg").default
                  })`,
                }}
              />
            </div>
            <div className="col-xl-3 col-md-4 col-sm-5">
              <div className="hugo-location__content">
                <div>
                  <p>
                    W stylowej śródmiejskiej enklawie, możesz cieszyć się
                    miastem w pełnym wydaniu o&nbsp;krok od domu.
                  </p>
                </div>
                <div className="hugo-location__content-small">
                  <p>
                    Dom pod Lwem
                    <br />i Słodownia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hugo-location__map">
          <img
            src={require("assets/images/hugo/location-map.svg").default}
            alt="Mapa"
          />
        </div>
      </section>
    </>
  )
}

export default Location
